
import { defineComponent } from "vue";
import ContentCard from "@/components/ContentCard.vue";
import ApiGrid from "@/components/ApiGrid.vue";
import { communityGalleryImageColumns } from "@/columns";
import { routeNames } from "@/router/routeNames";

export default defineComponent({
    components: {
        "content-card": ContentCard,
        "api-grid": ApiGrid
    },
    setup() {
        return {
            communityGalleryImageColumns,
            routeNames
        };
    }
});
